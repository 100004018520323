export const contentConstants = {
	REQUEST: 'CONTENT_REQUEST',
	SUCCESS: 'CONTENT_SUCCESS',
	FAILURE: 'CONTENT_FAILURE',

	USER_CONTENT_REQUEST: 'GET_USER_CONTENT_REQUEST',
	USER_CONTENT_SUCCESS: 'GET_USER_CONTENT_SUCCESS',
	USER_CONTENT_FAILURE: 'GET_USER_CONTENT_FAILURE',

	CREATE_REQUEST: 'CREATE_CONTENT_REQUEST',
	CREATE_SUCCESS: 'CREATE_CONTENT_SUCCESS',
	CREATE_FAILURE: 'CREATE_CONTENT_FAILURE',

	CLEAN: 'CLEAN_CONTENT',

	UPDATE_REQUEST: 'UPDATE_CONTENT_REQUEST',
	UPDATE_SUCCESS: 'UPDATE_CONTENT_SUCCESS',
	UPDATE_FAILURE: 'UPDATE_CONTENT_FAILURE',

	DELETE_REQUEST: 'DELETE_CONTENT_REQUEST',
	DELETE_SUCCESS: 'DELETE_CONTENT_SUCCESS',
	DELETE_FAILURE: 'DELETE_CONTENT_FAILURE',

	PUBLISH_REQUEST: 'PUBLISH_CONTENT_REQUEST',
	PUBLISH_SUCCESS: 'PUBLISH_CONTENT_SUCCESS',
	PUBLISH_FAILURE: 'PUBLISH_CONTENT_FAILURE',

	TYPE_THEME_REQUEST: 'TYPE_THEME_CONTENT_REQUEST',
	TYPE_THEME_SUCCESS: 'TYPE_THEME_CONTENT_SUCCESS',
	TYPE_THEME_FAILURE: 'TYPE_THEME_CONTENT_FAILURE',

	SET_TYPE_THEMES_REQUEST: 'SET_TYPE_THEMES_CONTENT_REQUEST',
	SET_TYPE_THEMES_SUCCESS: 'SET_TYPE_THEMES_CONTENT_SUCCESS',
	SET_TYPE_THEMES_FAILURE: 'SET_TYPE_THEMES_CONTENT_FAILURE',

	SET_FILE_REQUEST: 'SET_FILE_CONTENT_REQUEST',
	SET_FILE_SUCCESS: 'SET_FILE_CONTENT_SUCCESS',
	SET_FILE_FAILURE: 'SET_FILE_CONTENT_FAILURE',

	SET_COVER_REQUEST: 'SET_COVER_CONTENT_REQUEST',
	SET_COVER_SUCCESS: 'SET_COVER_CONTENT_SUCCESS',
	SET_COVER_FAILURE: 'SET_COVER_CONTENT_FAILURE',

	SET_REQUEST: 'SET_CONTENT_REQUEST',
	SET_SUCCESS: 'SET_CONTENT_SUCCESS',
	SET_FAILURE: 'SET_CONTENT_FAILURE',

	CONTENT_TYPE_REQUEST: 'GET_CONTENT_TYPE_CONTENT_REQUEST',
	CONTENT_TYPE_SUCCESS: 'GET_CONTENT_TYPE_CONTENT_SUCCESS',
	CONTENT_TYPE_FAILURE: 'GET_CONTENT_TYPE_CONTENT_FAILURE',

	CONTENT_ACTION_REQUEST: 'CONTENT_ACTION_REQUEST',
	CONTENT_ACTION_SUCCESS: 'CONTENT_ACTION_SUCCESS',
	CONTENT_ACTION_FAILURE: 'CONTENT_ACTION_FAILURE',

	GET_FILTERED: 'GET_FILTERED_CONTENT',
	RESET_FILTERED: 'RESET_FILTERED_CONTENT',
	LOAD_MORE_FILTERED: 'LOAD_MORE_FILTERED_CONTENT',

	REQUEST_CONTENT_URL: 'REQUEST_CONTENT_URL',
	SUCCESS_CONTENT_URL: 'SUCCESS_CONTENT_URL',
	FAILURE_CONTENT_URL: 'FAILURE_CONTENT_URL',

	SUCCESS_ABOUT: 'SUCCESS_ABOUT',
	SUCCESS_FAVORITE: 'SUCCESS_FAVORITE',
	UPDATE_INFOGRAPHIC: 'UPDATE_INFOGRAPHIC',
	SUCCESS_INFOGRAPHIC: 'SUCCESS_INFOGRAPHIC',
	DELETE_INFOGRAPHIC_SUCCESS: 'DELETE_INFOGRAPHIC_SUCCESS',
	CLEAN_INFOGRAPHIC: 'CLEAN_INFOGRAPHIC'
}
