import { useEffect } from 'react'

/**
 * Listen to scroll on landing page to load more content with infinite scroll
 */
export const useInfiniteScroll = (loader, loadMore) => {
	useEffect(() => {
		const options = {
			root: null, // window by default
			rootMargin: '0px',
			threshold: 0.25
		}

		// Create observer
		const observer = new IntersectionObserver(loadMore, options)

		// observer the loader
		const { current } = loader
		if (loader && current) {
			observer.observe(current)
		}

		// clean up on willUnMount
		return () => observer.unobserve(current)
	}, [loader, loadMore])
}
