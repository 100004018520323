import { isEqual } from 'lodash'
import { contentConstants } from '../_constants'
import { searchService } from '../_services'
import initialStateFilter from '../../context/filter.json'

export const searchActions = {
	resetFilteredContent,
	getFilteredContent,
	loadMoreFilteredContent
}

function getFilteredContent(params, page, perPage) {
	return (dispatch) => {
		if (isEqual(initialStateFilter, params)) {
			dispatch(resetFilteredContent())
		} else {
			searchService.getFilteredContent(params, page, perPage).then(
				(data) => dispatch(success(data)),
				(error) => console.error(error.toString())
			)
		}
	}

	function success(data) {
		return {
			type: contentConstants.GET_FILTERED,
			data
		}
	}
}

function resetFilteredContent() {
	return (dispatch) => {
		dispatch({ type: contentConstants.RESET_FILTERED })
	}
}

function loadMoreFilteredContent(params, page, perPage) {
	return (dispatch) => {
		searchService.loadMoreFilteredContent(params, page, perPage).then(
			(data) => dispatch(success(data, page)),
			(error) => console.error(error.toString())
		)
	}

	function success(data, page) {
		return {
			type: contentConstants.LOAD_MORE_FILTERED,
			data,
			page
		}
	}
}
