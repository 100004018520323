import React, { forwardRef, lazy, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { FilterProvider } from '../../../context/advancedFilter'
import { TourProvider } from '../../../context/tour'

import FilteredContent from '../FilteredContent'
import ContentIndication from '../ContentIndication'
import popupImage from '../../../assets/popup_podcast.jpg'
import { Button, Modal, TransitionablePortal, Image } from 'semantic-ui-react'

const MainPanel = lazy(() => import('../MainPanel'))
const Topics = lazy(() => import('../Topics'))
const Search = lazy(() => import('../Search'))
const ContentCategories = lazy(() => import('../ContentCategories'))

const PublicApp = forwardRef((_, ref) => {
	const { filtered } = useSelector((state) => state.content)

	const [popupOpen, setPopupOpen] = useState(false)

	// Basta incrementar/alterar pra resetar o popup para novas campanhas
	const popupStorageName = 'popup1'

	const handleHidePopup = () => {
		localStorage.setItem(popupStorageName, true)
	}

	useEffect(() => {
		try {
			const shouldHide = JSON.parse(
				localStorage.getItem(popupStorageName)
			)

			if (!shouldHide) {
				setTimeout(() => {
					setPopupOpen(false)
				}, 2000)
			}
		} catch {
			console.error('Erro ao resgatar dados do storage.')
		}
	}, [])

	return (
		<>
			<TourProvider>
				<div id="main">
					<MainPanel />
				</div>
			</TourProvider>
			<FilterProvider>
				<div style={{ height: 40 }}>
					<ContentIndication />
				</div>
				<Topics />
				<Search />
				<div id="content">
					{filtered && <FilteredContent ref={ref} data={filtered} />}
					{!filtered && <ContentCategories ref={ref} />}
				</div>
			</FilterProvider>
			<div style={{ height: '50px' }}>
				<TransitionablePortal
					open={popupOpen}
					transition={{ animation: 'scale', duration: 500 }}
				>
					<Modal
						open={true}
						onClose={() => {
							setPopupOpen(false)
						}}
						dimmer="blurring"
						size="tiny"
						closeIcon
					>
						<Modal.Content>
							<a
								href="https://anchor.fm/protejabrasil"
								target="blank"
							>
								<Image
									src={popupImage}
									alt="Evento Online - Lançamento do Portal Proteja"
								/>
							</a>
						</Modal.Content>
						<Modal.Actions>
							<Button
								color="black"
								size="small"
								onClick={() => {
									setPopupOpen(false)
									handleHidePopup()
								}}
							>
								Não ver mais essa mensagem
							</Button>
							<Button
								size="small"
								onClick={() => setPopupOpen(false)}
							>
								Fechar
							</Button>
						</Modal.Actions>
					</Modal>
				</TransitionablePortal>
			</div>
		</>
	)
})

export default PublicApp
