import React, { createContext, useContext, useState } from 'react'

export const TourContext = createContext({})

export const TourProvider = ({ children }) => {
	const [tour, setTour] = useState(false)
	const [tourDashboard, setTourDashboard] = useState(false)
	const [stepIndexTourDashboard, setStepIndexTourDashboard] = useState(0)

	return (
		<TourContext.Provider
			value={{
				tour,
				setTour,
				tourDashboard,
				setTourDashboard,
				stepIndexTourDashboard,
				setStepIndexTourDashboard
			}}
		>
			{children}
		</TourContext.Provider>
	)
}

export const useTour = () => useContext(TourContext)
