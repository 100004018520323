import React from 'react'

const Icon = ({ type, onClick, style }) => (
	<i className={`Icon ${type}`} style={style} onClick={onClick} />
)

Icon.defaultProps = {
	onClick: null
}

export default Icon
