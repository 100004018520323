import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const seriesService = {
	getAllSeries,
	getAllSerieByUser,
	createSerie,
	getSerieById,
	updateSerie,
	setCover,
	deleteSerie,
	getSerieByUrl,
	sendSerieToApproval,
	getAllSerieToApproval,
	setCurator,
	defineContent
}

function getAllSeries(page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(
		`${config.apiUrl}/series/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function createSerie(serie) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(serie)
	}

	return fetch(`${config.apiUrl}/series/`, requestOptions).then(
		handleResponse
	)
}

function updateSerie(idSerie, serie) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(serie)
	}

	return fetch(`${config.apiUrl}/series/${idSerie}`, requestOptions).then(
		handleResponse
	)
}

function getSerieById(idSerie) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/series/${idSerie}`, requestOptions).then(
		handleResponse
	)
}

function getAllSerieByUser(page, perPage, idUser) {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiUrl}/users/${idUser}/series/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function setCover(idSerie, cover) {
	let formData = new FormData()
	formData.append('cover', cover)

	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(
		`${config.apiUrl}/series/${idSerie}/cover`,
		requestOptions
	).then(handleResponse)
}

function deleteSerie(idSerie) {
	const requestOptions = {
		method: 'DELETE',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/series/${idSerie}`, requestOptions).then(
		handleResponse
	)
}

function getSerieByUrl(url) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() }
	}

	return fetch(`${config.apiUrl}/series/${url}`, requestOptions).then(
		handleResponse
	)
}

function sendSerieToApproval(idSerie) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/series/${idSerie}/to-approval`,
		requestOptions
	).then(handleResponse)
}

function getAllSerieToApproval(page, perPage, curators) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	if (curators) {
		return fetch(
			`${config.apiUrl}/series/waiting-approval-curator/paginate/${page}/${perPage}`,
			requestOptions
		).then(handleResponse)
	} else {
		return fetch(
			`${config.apiUrl}/series/waiting-approval-no-curator/paginate/${page}/${perPage}`,
			requestOptions
		).then(handleResponse)
	}
}

function setCurator(idContent, curator_id) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ curator_id })
	}

	return fetch(
		`${config.apiUrl}/series/${idContent}/curator`,
		requestOptions
	).then(handleResponse)
}

function defineContent(idContent, type, message) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ message })
	}

	return fetch(
		`${config.apiUrl}/series/${idContent}/${type}`,
		requestOptions
	).then(handleResponse)
}
