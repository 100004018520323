export const seriesConstants = {
	REQUEST: 'SERIES_REQUEST',
	SUCCESS: 'SERIES_SUCCESS',
	FAILURE: 'SERIES_FAILURE',

	USER_SERIE_REQUEST: 'GET_USER_SERIE_REQUEST',
	USER_SERIE_SUCCESS: 'GET_USER_SERIE_SUCCESS',
	USER_SERIE_FAILURE: 'GET_USER_SERIE_FAILURE',

	CREATE_REQUEST: 'CREATE_SERIES_REQUEST',
	CREATE_SUCCESS: 'CREATE_SERIES_SUCCESS',
	CREATE_FAILURE: 'CREATE_SERIES_FAILURE',

	UPDATE_REQUEST: 'UPDATE_SERIE_REQUEST',
	UPDATE_SUCCESS: 'UPDATE_SERIE_SUCCESS',
	UPDATE_FAILURE: 'UPDATE_SERIE_FAILURE',

	DELETE_REQUEST: 'DELETE_SERIE_REQUEST',
	DELETE_SUCCESS: 'DELETE_SERIE_SUCCESS',
	DELETE_FAILURE: 'DELETE_SERIE_FAILURE',

	CLEAN: 'CLEAN_SERIE',

	SET_COVER_REQUEST: 'SET_COVER_SERIES_REQUEST',
	SET_COVER_SUCCESS: 'SET_COVER_SERIES_SUCCESS',
	SET_COVER_FAILURE: 'SET_COVER_SERIES_FAILURE',

	REQUEST_URL: 'SERIES_URL_REQUEST',
	SUCCESS_URL: 'SERIES_URL_SUCCESS',
	FAILURE_URL: 'SERIES_URL_FAILURE',

	SEND_TO_APPROVAL: 'SERIES_SEND_TO_APPROVAL',
	SUCCESS_SEND_TO_APPROVAL: 'SERIES_SEND_TO_APPROVAL_SUCESS',
	FAILURE_SEND_TO_APPROVAL: 'SERIES_SEND_TO_APPROVAL_FAILURE',

	SET_REQUEST: 'SET_SERIE_REQUEST',
	SET_SUCCESS: 'SET_SERIE_SUCCESS',
	SET_FAILURE: 'SET_SERIE_FAILURE'
}
