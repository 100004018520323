import { locationConstants } from '../_constants'

export function conservations(state = { conservations: [] }, action) {
	switch (action.type) {
		case locationConstants.CONSERVATION_REQUEST:
			return {
				fetchingConservations: true,
				...state
			}
		case locationConstants.CONSERVATION_SUCCESS: {
			return {
				conservations: action.rows
			}
		}
		case locationConstants.CONSERVATION_FAILURE:
			return {
				conservations: []
			}
		default:
			return state
	}
}
