import { seriesConstants } from '../_constants'
import { seriesService } from '../_services'
import { alertActions } from './alert.actions'

export const seriesActions = {
	getAllSeries,
	getAllSerieByUser,
	createSerie,
	updateSerie,
	cleanSerie,
	getSerieById,
	setCover,
	deleteSerie,
	getSerieByUrl,
	sendSerieToApproval,
	getAllSerieToApproval,
	setCurator,
	defineContent
}

function getAllSeries(page, perPage) {
	return (dispatch) => {
		dispatch(request())
		seriesService.getAllSeries(page, perPage).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: seriesConstants.REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.FAILURE, error }
	}
}

function getAllSerieByUser(page, perPage, idUser) {
	return (dispatch) => {
		dispatch(request())

		seriesService.getAllSerieByUser(page, perPage, idUser).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: seriesConstants.USER_SERIE_REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.USER_SERIE_SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.USER_SERIE_FAILURE, error }
	}
}

function getSerieById(idSerie) {
	return (dispatch) => {
		dispatch(request())
		seriesService.getSerieById(idSerie).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: seriesConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.CREATE_FAILURE, error }
	}
}

function createSerie(serie) {
	return (dispatch) => {
		dispatch(request())
		seriesService.createSerie(serie).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: seriesConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.CREATE_FAILURE, error }
	}
}

function updateSerie(idSerie, serie) {
	return (dispatch) => {
		dispatch(request())
		seriesService.updateSerie(idSerie, serie).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: seriesConstants.UPDATE_REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.UPDATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.UPDATE_FAILURE, error }
	}
}

function cleanSerie() {
	return (dispatch) => {
		dispatch({ type: seriesConstants.CLEAN })
	}
}

function setCover(idSerie, cover) {
	return (dispatch) => {
		dispatch(request())
		seriesService.setCover(idSerie, cover).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: seriesConstants.SET_COVER_REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.SET_COVER_SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.SET_COVER_FAILURE, error }
	}
}

function deleteSerie(idSerie) {
	return (dispatch) => {
		dispatch(request())
		seriesService.deleteSerie(idSerie).then(
			(data) => {
				dispatch(success(idSerie))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: seriesConstants.DELETE_REQUEST }
	}
	function success(id) {
		return { type: seriesConstants.DELETE_SUCCESS, id }
	}
	function failure(error) {
		return { type: seriesConstants.DELETE_FAILURE, error }
	}
}

function getSerieByUrl(url) {
	return (dispatch) => {
		dispatch(request(url))

		seriesService.getSerieByUrl(url).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request(url) {
		return {
			type: seriesConstants.REQUEST_URL,
			url
		}
	}
	function success(serie) {
		return {
			type: seriesConstants.SUCCESS_URL,
			serie
		}
	}
	function failure() {
		return {
			type: seriesConstants.FAILURE_URL
		}
	}
}

function sendSerieToApproval(idSerie) {
	return (dispatch) => {
		dispatch(request())
		seriesService.sendSerieToApproval(idSerie).then(
			(data) => {
				dispatch(success(idSerie))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: seriesConstants.SEND_TO_APPROVAL }
	}
	function success(id) {
		return { type: seriesConstants.SUCCESS_SEND_TO_APPROVAL, id }
	}
	function failure(error) {
		return { type: seriesConstants.FAILURE_SEND_TO_APPROVAL, error }
	}
}

function getAllSerieToApproval(page, perPage, curators) {
	return (dispatch) => {
		dispatch(request())
		seriesService.getAllSerieToApproval(page, perPage, curators).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: seriesConstants.REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.FAILURE, error }
	}
}

function setCurator(idContent, curator_id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			seriesService.setCurator(idContent, curator_id).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: seriesConstants.SET_REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.SET_FAILURE, error }
	}
}

function defineContent(idContent, type, message) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			seriesService.defineContent(idContent, type, message).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: seriesConstants.SET_REQUEST }
	}
	function success(data) {
		return { type: seriesConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: seriesConstants.SET_FAILURE, error }
	}
}
