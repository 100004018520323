import styled from 'styled-components'

export const Wrapper = styled.div`
	margin: 0 5px;
`
export const Block = styled.div`
	position: relative;
	height: 9.5vw;
	.content {
		:hover {
			background-color: ${({ theme }) => theme.colors.dark};
			cursor: pointer;
		}
		position: absolute;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
		transition: background-color ease 0.25s;
		overflow: hidden;
	}
	img {
		height: 9.5vw;
		width: 100%;
		pointer-events: none;
		object-fit: cover;
	}
	@media screen and (max-width: 1024px) {
		height: 150px;
		img {
			height: 150px;
		}
	}
`

export const Icon = styled.div`
	margin-top: -45px;
	position: relative;
	top: 45px;
	z-index: 2;
	background-color: ${({ theme }) => theme.colors.light};
	width: 50px;
	padding: 10px 10px 15px;
	border-bottom-right-radius: 100%;
	i {
		font-size: 20px;
		color: ${({ theme }) => theme.colors.dark};
	}
`

export const Description = styled.div`
	padding: 0 10px;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.primary};
	height: 70px;
	border-bottom-left-radius: 4px;
	background-color: ${({ theme }) => theme.colors.light};
	border-bottom-right-radius: 4px;
	transition: all 0.25s linear;
	&:hover {
		background-color: ${({ theme }) => theme.colors.dark};
		color: #ffffff;
		cursor: pointer;
	}
	@media screen and (max-width: 1024px) {
		font-size: 12px;
	}
`
