import { createStore, applyMiddleware } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './_reducers'

const loggerMiddleware = createLogger({
	predicate: () => process.env.NODE_ENV === 'development'
})

const store = createStore(
	rootReducer,
	applyMiddleware(thunkMiddleware, loggerMiddleware)
)

export default store
