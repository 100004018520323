import { filteredConstants } from '../_constants'

export const filterRecent = (selected) => ({
	type: filteredConstants.FILTERRECENT,
	payload: selected
})

export const filterSeries = (selected) => ({
	type: filteredConstants.FILTERSERIES,
	payload: selected
})

export const filterName = (selected) => ({
	type: filteredConstants.FILTERNAME,
	payload: selected
})

export const filterContent = (recents) => ({
	type: filteredConstants.FILTERRECENTCONT,
	payload: recents
})
