import React, { createRef, lazy, Suspense, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, userActions } from './app/_actions'
import history from './app/history'
import { TourProvider } from './context/tour'

import SuspenseLoader from './components/SuspenseLoader'
import PublicApp from './components/LandingPage/PublicApp'
const ContentPage = lazy(() => import('./components/LandingPage/ContentPage'))
const SeriePage = lazy(() => import('./components/LandingPage/SeriePage'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Sign = lazy(() => import('./pages/LandingPage/Sign'))
const Term = lazy(() => import('./pages/LandingPage/Term'))
const Contact = lazy(() => import('./pages/LandingPage/Contact'))
const Partners = lazy(() => import('./pages/LandingPage/Partners'))
const Program = lazy(() => import('./pages/LandingPage/Program'))
const About = lazy(() => import('./pages/LandingPage/About'))
const ResetPassword = lazy(() => import('./pages/LandingPage/ResetPassword'))
const EmailConfirm = lazy(() => import('./pages/LandingPage/EmailConfirm'))

const Navbar = lazy(() => import('./components/Navbar'))

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'semantic-ui-css/semantic.min.css'

const Logout = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(userActions.logout())
	}, [dispatch])
	return <Redirect to={{ pathname: '/' }} />
}

const App = () => {
	const dispatch = useDispatch()
	const { location } = useSelector((state) => state.alert)
	const { user } = useSelector((state) => state.authentication)

	ReactGA.initialize(process.env.REACT_APP_ANALYTICS)
	if (user) {
		ReactGA.set({
			userId: user.email
		})
	}

	useEffect(() => {
		dispatch(alertActions.setLocation(history.location.pathname))
		history.listen(() => {
			dispatch(alertActions.clear())
			dispatch(alertActions.setLocation(history.location.pathname))
			ReactGA.set({ page: history.location.pathname })
			// ReactGA.pageview(history.location.pathname)
		})
		dispatch(userActions.hasToken())
	}, [dispatch])

	const navRef = createRef()
	const isDashboard = location ? location.includes('painel') : false
	return (
		<Router history={history}>
			<Suspense fallback={<SuspenseLoader />}>
				<Navbar ref={navRef} />
				<Switch>
					<Route exact path="/">
						<PublicApp ref={navRef} />
					</Route>
					<Route path="/conteudo/:url" component={ContentPage} />
					<Route path="/serie/:url" component={SeriePage} />
					<Route path="/login" component={Sign} />
					<Route path="/login-es" component={Sign} />
					<Route path="/logout" component={Logout} />
					<Route path="/mudar-senha" component={ResetPassword} />
					<Route path="/confirma-email" component={EmailConfirm} />
					<Route path="/termo-e-condicoes-de-uso" component={Term} />
					<Route path="/parceiros" component={Partners} />
					<Route path="/iniciativa-proteja" component={Program} />
					<Route path="/fale-conosco" component={Contact} />
					<Route path="/como-usar" component={About} />
					<Route path="/painel">
						<TourProvider>
							<Dashboard />
						</TourProvider>
					</Route>
					{!isDashboard && <Redirect from="*" to="/" />}
				</Switch>
			</Suspense>
		</Router>
	)
}

export default App
