import { combineReducers } from 'redux'

import { authentication } from './authentication.reducer'
import { registration } from './registration.reducer'
import { users } from './users.reducer'
import { orgs } from './orgs.reducer'
import { regions } from './region.reducer'
import { states } from './state.reducer'
import { cities } from './city.reducer'
import { biomes } from './biomes.reducer'
import { conservations } from './conservation.reducer'
import { indigenous } from './indigenous.reducer'
import { content } from './content.reducer'
import { series } from './series.reducer'
import { types } from './types.reducer'
import { themes } from './themes.reducer'
import { alert } from './alert.reducer'
import boolFiltered from './filtered.reducer'

const rootReducer = combineReducers({
	authentication,
	registration,
	users,
	orgs,
	regions,
	states,
	cities,
	biomes,
	conservations,
	indigenous,
	content,
	series,
	types,
	themes,
	alert,
	boolFiltered
})

export default rootReducer
