import { locationConstants } from '../_constants'

export function regions(state = { regions: [] }, action) {
	switch (action.type) {
		case locationConstants.REGION_REQUEST:
			return {
				fetchingRegions: true,
				...state
			}
		case locationConstants.REGION_SUCCESS: {
			return {
				regions: action.rows
			}
		}
		case locationConstants.REGION_FAILURE:
			return {
				regions: []
			}
		default:
			return state
	}
}
