import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const contentDataService = {
	getAllTypes,
	getAllThemes,
	updateContenType
}

function getAllTypes() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiUrl}/contenttype/`, requestOptions).then(
		handleResponse
	)
}

function getAllThemes() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiUrl}/themes/`, requestOptions).then(
		handleResponse
	)
}

function updateContenType(id, data) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	}

	return fetch(`${config.apiUrl}/contenttype/${id}`, requestOptions).then(
		handleResponse
	)
}
