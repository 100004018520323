import { locationConstants } from '../_constants'

export function indigenous(state = { indigenous: [] }, action) {
	switch (action.type) {
		case locationConstants.INDIGENOUS_REQUEST:
			return {
				fetchingIndigenous: true,
				...state
			}
		case locationConstants.INDIGENOUS_SUCCESS: {
			return {
				indigenous: action.rows
			}
		}
		case locationConstants.INDIGENOUS_FAILURE:
			return {
				indigenous: []
			}
		default:
			return state
	}
}
