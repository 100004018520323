import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contentActions } from '../../../app/_actions'
import history from '../../../app/history'

import ModalShare from '../ModalShare'
import Icon from '../../Icon'

import * as S from './styles'

function ContentUserActions({ id, url, item, handleSelect }) {
	const [actions, setActions] = useState({})
	useEffect(() => {
		setActions({
			like: {
				icon: 'far fa-thumbs-up',
				tip: 'Marcar como Gostei',
				action: 'like',
				activeIcon: 'fad fa-thumbs-up',
				counterType: 'dislike',
				key: 'is_liked_by_current_user',
				counterKey: 'is_disliked_by_current_user',
				active: item.is_liked_by_current_user
			},
			dislike: {
				icon: 'far fa-thumbs-down',
				tip: 'Marcar como Não gostei',
				action: 'dislike',
				activeIcon: 'fad fa-thumbs-down',
				counterType: 'like',
				key: 'is_disliked_by_current_user',
				counterKey: 'is_liked_by_current_user',
				active: item.is_disliked_by_current_user
			},
			favorite: {
				icon: 'far fa-heart',
				tip: 'Marcar como Favorito',
				action: 'favorite',
				activeIcon: 'fa fa-heart',
				key: 'is_favorited_by_current_user',
				active: item.is_favorited_by_current_user
			},
			share: {
				icon: 'far fa-share-alt',
				tip: 'Compartilhar conteúdo',
				activeIcon: 'far fa-share-alt',
				action: 'share'
			}
		})
	}, [item, setActions])
	const { loggedIn } = useSelector((state) => state.authentication)
	const [openShare, setOpenShare] = useState(false)
	const dispatch = useDispatch()
	const handleAction = (type, active, counterType, key, counterKey) => {
		if (type === 'share') {
			setOpenShare(true)
		} else {
			if (loggedIn) {
				if (active) {
					switch (type) {
						case 'favorite':
							dispatch(
								contentActions.actionContent('unfavorite', id)
							)
							break
						case 'like':
						case 'dislike':
							dispatch(
								contentActions.actionContent('remove-like', id)
							)
							break
					}
				} else {
					dispatch(contentActions.actionContent(type, id))
				}
			} else {
				return history.push('/login')
			}
			// Atualiza o estado atual
			if (type == 'favorite') {
				setActions({
					...actions,
					[type]: {
						...actions[type],
						active: !active
					}
				})
			} else {
				setActions({
					...actions,
					[type]: {
						...actions[type],
						active: !active
					},
					[counterType]: {
						...actions[counterType],
						active: actions[counterType].active ? false : null
					}
				})
			}
			// Mantém o estado novo durante os desmounts do componente
			item[key] = !active
			if (!active) {
				item[counterKey] = false
			}
			handleSelect(item)
		}
	}

	return (
		<S.Actions>
			{Object.values(actions).map((actionItem) => (
				<div key={actionItem.action}>
					<div data-tip={actionItem.tip}>
						<Icon
							type={
								actionItem.active
									? actionItem.activeIcon
									: actionItem.icon
							}
							style={
								actionItem.active
									? { color: 'rgb(220, 94, 20)' }
									: {}
							}
							onClick={() =>
								handleAction(
									actionItem.action,
									actionItem.active,
									actionItem.counterType,
									actionItem.key,
									actionItem.counterKey,
									item
								)
							}
							data-tip={actionItem.tip}
							id={actionItem.icon}
						/>
					</div>
				</div>
			))}
			<ModalShare
				open={openShare}
				handleOpen={setOpenShare}
				url={'conteudo/' + url}
			/>
		</S.Actions>
	)
}

export default ContentUserActions
