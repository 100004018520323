import { useEffect } from 'react'

/**
 * Hook for handling click on content details in landingpage
 */
export const useScrollDetails = (category, top, ref) => {
	useEffect(() => {
		if (!category) return
		const navHeight = ref.current.offsetHeight

		window.scrollTo({
			top: top + window.scrollY - navHeight,
			left: 0,
			behavior: 'smooth'
		})

		return
	}, [category, ref, top])
}
