import React from 'react'
import { isEqual } from 'lodash'
import { useFilter } from '../../../context/advancedFilter'
import initialStateFilter from '../../../context/filter.json'

import Icon from '../../Icon'

import * as S from './styles'

function ContentIndication() {
	const { filter } = useFilter()

	const goToResults = () => {
		try {
			const element = document.getElementById('content')
			window.scrollTo({
				top: element.offsetTop,
				behavior: 'smooth'
			})
		} catch (e) {
			console.error('Erro ao navegar até os conteúdos', e)
		}
	}
	const show = isEqual(initialStateFilter, filter)
	return (
		!show && (
			<S.Container>
				<div
					onClick={goToResults}
					data-tip="Veja os resultados dos filtro abaixo!"
				>
					<Icon type="fad fa-chevron-double-down"></Icon>
				</div>
			</S.Container>
		)
	)
}

export default ContentIndication
