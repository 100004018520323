import { Label } from 'semantic-ui-react'
import styled from 'styled-components'

export const Detail = styled.div`
	${({ isPage, theme }) =>
		isPage
			? `
			height: 100%;
		`
			: `
			height: 475px;
			background: ${theme.colors.darkLight};
			position: absolute;
			z-index: 99;
			border: 2px solid white;
		`}
	width: calc(100% - 120px);
	top: ${(props) => props.pos}px;
	@media screen and (max-width: 800px) {
		width: calc(100% - 80px);
		margin: 0 40px;
	}
	& > .Icon {
		font-size: 32px;
		color: white;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 2;
		cursor: pointer;
	}
`

export const WrapOverview = styled.div`
	&&.half {
		flex: 1;
		margin-right: 10px;
		@media screen and (max-width: 1280px) {
			> div {
				overflow: unset;
			}
		}
	}
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Overview = styled.div`
	${({ isPage }) =>
		isPage
			? `
			height: 100%;
		`
			: `
			max-height: 440px;
			overflow-x: auto;
		`}
	flex: 2;
	color: white;
	@media screen and (max-width: 1280px) {
		&&.half {
			overflow: unset;
			max-height: unset;
		}
	}
	.Icon {
		margin-right: 10.5px;
	}
	p {
		padding: 10px 20px 0;
	}
	hr {
		color: #fff;
	}
	button {
		padding: 0.5em 2em;
		font-size: 15px;
	}
	button:first-of-type {
		background: ${({ theme }) => theme.colors.secondary};
		:hover {
			background-color: rgb(12 12 12);
		}
	}
	::-webkit-scrollbar {
		width: 0.25em;
	}
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.primary};
	}
`

export const Header = styled.div`
	display: flex;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}
`
export const Info = styled.div`
	flex: 1;
`

export const Cover = styled.div`
	background-image: url('${(props) => props.bg}');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	min-width: 400px;
	height: 400px;
	margin: 20px;
`

export const Authors = styled.div`
	margin-left: 20px;
	margin-bottom: 10px;
	color: ${({ theme }) => theme.colors.dark};
	font-weight: bold;
`

export const Colaborators = styled.div`
	margin-left: 20px;
	margin-bottom: 10px;
	color: ${({ theme }) => theme.colors.dark};
	font-weight: bold;
`

export const Short = styled.p`
	font-size: 16px;
	padding-top: 20px;
	color: ${({ theme }) => theme.colors.dark};
	font-weight: bold;
`

export const Keywords = styled(Label.Group)`
	margin-left: 20px;
	> div {
		color: ${({ theme }) => theme.colors.dark};
		font-weight: bold;
		margin-bottom: 10px;
	}
`

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.primary};
	font-weight: bold;
	margin-bottom: 20px;
	padding-top: 0 !important;
`

export const Date = styled.div`
	margin-left: 20px;
	padding-bottom: 10px;
	color: ${({ theme }) => theme.colors.dark};
	font-weight: bold;
`

export const Themes = styled(Label.Group)`
	padding: 0 20px;
	> div {
		color: ${({ theme }) => theme.colors.dark};
		font-weight: bold;
		margin-bottom: 10px;
	}
	.ui.big.label {
		background-color: ${({ theme }) => theme.colors.primary};
		color: #ffffff;
		:hover {
			background-color: ${({ theme }) => theme.colors.primaryHover};
		}
	}
`

export const FileLink = styled.div`
	display: flex;
	align-items: center;
	margin-left: 20px;
	margin-bottom: 20px;
	> a:hover {
		color: inherit;
	}
	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}
`

export const ContentFileLink = styled.div`
	flex: 1;
	transition: background-color 0.25s linear;
	font-size: 16px;
	border-radius: 4px;
	padding: 10px;
	:hover {
		cursor: pointer;
		${({ isLink, theme }) =>
			isLink
				? `
			background-color: ${theme.colors.secondaryHover};
			`
				: `
			background-color: ${theme.colors.primaryHover};
			`}
		color: white;
	}
	${({ isLink, theme }) =>
		isLink
			? `
			background-color: ${theme.colors.secondary};
			min-width: 140px;
			`
			: `
			margin-right: 10px;
			background-color: ${theme.colors.primary}; `}
`

export const InfoSerie = styled.div`
	flex: 1;
	padding: 0 20px;
	h2 {
		color: ${({ theme }) => theme.colors.secondary};
	}
	> div {
		max-height: 350px;
	}
`

export const Episodes = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	h2 {
		color: ${({ theme }) => theme.colors.primary};
		font-weight: bold;
	}
	@media screen and (max-width: 1280px) {
		margin-left: 20px;
	}
`
export const List = styled.div`
	flex-direction: column;
	width: 800px;
	overflow-x: auto;
	height: 400px;
	margin-right: 2px;
	@media screen and (max-width: 1280px) {
		overflow: unset;
		height: unset;
		width: unset;
	}
	.title {
		color: ${({ theme }) => theme.colors.primary};
	}
	::-webkit-scrollbar {
		width: 0.25em;
	}
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.primary};
	}
`

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	position: relative;
	overflow: hidden;
	justify-content: space-between;
	margin-top: 20px;
	@media screen and (max-width: 1280px) {
		&&.is-serie {
			overflow-y: auto;
			max-height: 450px;
			> div {
				overflow: unset;
				max-height: unset;
			}
			.cover {
				display: block;
			}
		}
		flex-direction: column;
		.cover {
			display: none;
		}
	}
	::-webkit-scrollbar {
		width: 0.25em;
	}
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.primary};
	}
`

export const Item = styled.div`
	display: flex;
	color: white;
	:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.light};
	}
	margin: 5px 0;
	transition: all 0.25s linear;
	&&.selected,
	:hover {
		background-color: ${({ theme }) => theme.colors.primaryHover};
		cursor: pointer;
	}
`
export const Image = styled.div`
	height: 120px;
	min-width: 200px;
	background-image: url('${(props) => props.img}');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 5px;
	padding: 20px;
	@media screen and (max-width: 800px) {
		display: none;
	}
`

export const Description = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 20px;
	position: relative;
	color: ${({ theme }) => theme.colors.dark};
	font-weight: bold;
	p {
		font-size: 14px;
	}
	.minutes {
		position: absolute;
		bottom: 5px;
		right: 0;
	}
`
