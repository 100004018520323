import React, { useState, useCallback } from 'react'
import LazyImage from '../../LazyImage'

import { truncate } from '../../../utils/functions'

import * as S from './styles'
import Icon from '../../Icon'

/**
 * @function ContentBlock
 */
const ContentBlock = ({ children, img, text, onClick, icon }) => {
	const [hovered, setHovered] = useState(false)

	const handleHover = useCallback((e, img) => {
		e.type === 'mouseenter' ? setHovered(img) : setHovered(false)
	}, [])

	return (
		<S.Wrapper onClick={onClick}>
			{icon && (
				<S.Icon>
					<Icon type={'fad fa-' + icon} color="black" />
				</S.Icon>
			)}
			<S.Block
				className="img"
				onMouseEnter={(e) => handleHover(e, img)}
				onMouseLeave={handleHover}
			>
				{img === hovered && <div className="content">{children}</div>}
				<LazyImage src={img} />
			</S.Block>
			<S.Description>{truncate(text, 60)}</S.Description>
		</S.Wrapper>
	)
}

export default ContentBlock
