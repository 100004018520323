const config = {
	apiUrl: process.env.REACT_APP_IBGE,
	apiIsaUrl: process.env.REACT_APP_ISA,
	apiIsaKey: process.env.REACT_APP_ISA_KEY
}

export const locationService = {
	getRegions,
	getStates,
	getBiomes,
	getCities,
	getConservations,
	getIndigenous
}

function getRegions() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiUrl}/localidades/regioes`, requestOptions)
		.then((response) => response.json())
		.then((data) => {
			return data
		})
}

function getStates() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiUrl}/localidades/estados`, requestOptions)
		.then((response) => response.json())
		.then((data) => {
			return data
		})
}

function getCities(state) {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiUrl}/localidades/estados/${state}/municipios`,
		requestOptions
	)
		.then((response) => response.json())
		.then((data) => {
			return data
		})
}

function getBiomes() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiIsaUrl}/bioma?apikey=${config.apiIsaKey}`,
		requestOptions
	)
		.then((response) => response.json())
		.then(({ data }) => {
			return data
		})
}

function getConservations() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiIsaUrl}/parceiro/uc?apikey=${config.apiIsaKey}`,
		requestOptions
	)
		.then((response) => response.json())
		.then(({ data }) => {
			return data
		})
}

function getIndigenous() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiIsaUrl}/parceiro/ti?apikey=${config.apiIsaKey}`,
		requestOptions
	)
		.then((response) => response.json())
		.then(({ data }) => {
			return data
		})
}
