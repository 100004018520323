import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

function SuspenseLoader() {
	return (
		<div style={{ width: '100vh' }}>
			<Dimmer active inverted>
				<Loader
					size="large"
					content="Carregando. Por favor, aguarde..."
				/>
			</Dimmer>
		</div>
	)
}

export default SuspenseLoader
