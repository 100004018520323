import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        border: 0;
        box-sizing: border-box;
    }
    html {
        font-size: 62.5%;        
    }
    html, body {
        height: 100%;
    }
    ol, ul {
        list-style: none;
    }
    a {
        text-decoration: none; /* no underline */
        color: white;
        :hover {
            color: ${({ theme }) => theme.colors.secondary};
        }
    }
    button:focus {
        outline: none;
    }
    p {
        font-size: 20px;
    }
    h2 {
        font-size: 1.4vw;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
        }
    }
    #navigation {
        display: none;
    }
    .container {
        padding: 0 60px;
        @media screen and (max-width: 800px) {
            padding: 0 40px;
        }
        @media screen and (max-width: 320px) {
            padding: 0 20px;
        }
    }
    button.default {
        background-color: ${({ theme }) => theme.colors.dark};
        border: 1px solid white;
        padding: 0 2.3em;
        border-radius: 20px;
        box-shadow: none;
        font-size: 1.1vw;
        color: white;
        height: 45px;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0.4px;
        transition: background-color .4s;
        @media screen and (max-width: 800px) {
            padding: 10px 5px;
            min-width: 120px;
            font-size: 12px;
        }
        :hover {
           background-color: ${({ theme }) => theme.colors.darkHover};
        }
        :focus {
            outline: none;
        }
        .icon {
            position: unset;
        }
    }
    .styled {
        &&& {
            text-transform: uppercase;
            background-color: ${({ theme }) => theme.colors.secondary};
            color: white;
            :hover,
            :focus,
            :visited {
                background-color: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .icon {
        color: white;
        margin-right: 5px;
    }
    .dropdown .icon,
    .input .icon {
        color: black;
    }

    .ui.form .field.field input:-webkit-autofill,
    .ui.form .field.field input:-webkit-autofill:focus {
        box-shadow: 0 0 0 100px white inset !important;
        border-color: #85b7d9 !important;
    }
    .ui.modal > .content,
    .ui.header .sub.header,
    .ui.button {
        font-size: 1.5rem;
    }    

    .shake {
        width: 98%;
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
        margin-bottom: 20px;
    }

    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }
    @keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-15px);
        }
        }
        @-webkit-keyframes bounce {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-15px);
        }
    }
`

export default GlobalStyle
