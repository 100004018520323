import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const contentService = {
	getAllContent,
	getAllContentToApproval,
	loadContentSelection,
	createContent,
	updateContent,
	publishContent,
	deleteContent,
	setTypeAndThemes,
	getContentById,
	getAllContentByUser,
	getAllContentByType,
	getAllRecentContent,
	setFile,
	setCurator,
	defineContent,
	setCover,
	actionContent,
	getContentByUrl,
	postComment,
	getUserFavorites,
	putComment,
	deleteComment,
	getAbout,
	setAbout,
	setAboutImage,
	getInfographics,
	setInfographic,
	setInfographicFile,
	deleteInfographic
}

function getAllContent(page, perPage, search = null) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	let url = `${config.apiUrl}/content/paginate/${page}/${perPage}`
	if (search) {
		url += `?q=${search}`
	}
	return fetch(url, requestOptions).then(handleResponse)
}

function getAllContentToApproval(page, perPage, curators) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	if (curators) {
		return fetch(
			`${config.apiUrl}/content/waiting-approval-curator/paginate/${page}/${perPage}`,
			requestOptions
		).then(handleResponse)
	} else {
		return fetch(
			`${config.apiUrl}/content/waiting-approval-no-curator/paginate/${page}/${perPage}`,
			requestOptions
		).then(handleResponse)
	}
}

async function loadContentSelection(q, loadedOptions, { page }) {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader()
		}
	}
	const response = await fetch(
		`${config.apiUrl}/content/paginate/${page}/10?q=${q}`,
		requestOptions
	)
	const responseJSON = await response.json()
	const hasMore = responseJSON.has_next

	return {
		options: responseJSON.content.map((o) => ({
			value: o.id,
			label: o.title
		})),
		hasMore,
		additional: {
			page: hasMore ? page + 1 : page
		}
	}
}

function createContent(content) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(content)
	}

	return fetch(`${config.apiUrl}/content/`, requestOptions).then(
		handleResponse
	)
}

function updateContent(idContent, content) {
	/* Evita enviar string vazia em caso de inteiros */
	Object.keys(content).forEach((k) => {
		if (content[k] === '') content[k] = null
	})
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(content)
	}

	return fetch(`${config.apiUrl}/content/${idContent}`, requestOptions).then(
		handleResponse
	)
}

function deleteContent(idContent) {
	const requestOptions = {
		method: 'DELETE',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/content/${idContent}`, requestOptions).then(
		handleResponse
	)
}

function publishContent(id) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/${id}/to-approval`,
		requestOptions
	).then(handleResponse)
}

function setTypeAndThemes(idContent, content_type_id, themes) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ content_type_id, themes })
	}

	return fetch(
		`${config.apiUrl}/content/${idContent}/typeandtheme`,
		requestOptions
	).then(handleResponse)
}

function setFile(idContent, file) {
	let formData = new FormData()
	formData.append('file', file)

	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(
		`${config.apiUrl}/content/${idContent}/file`,
		requestOptions
	).then(handleResponse)
}

function setCover(idContent, cover) {
	let formData = new FormData()
	formData.append('cover', cover)
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(
		`${config.apiUrl}/content/${idContent}/cover`,
		requestOptions
	).then(handleResponse)
}

function setCurator(idContent, curator_id) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ curator_id })
	}

	return fetch(
		`${config.apiUrl}/content/${idContent}/curator`,
		requestOptions
	).then(handleResponse)
}

function defineContent(idContent, type, message) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ message })
	}

	return fetch(
		`${config.apiUrl}/content/${idContent}/${type}`,
		requestOptions
	).then(handleResponse)
}

function getContentById(idContent) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/content/${idContent}`, requestOptions).then(
		handleResponse
	)
}

function getAllContentByUser(page, perPage, idUser) {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiUrl}/users/${idUser}/content/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function getAllContentByType(page, perPage, idType) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/contenttype/${idType}/content/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function getAllRecentContent(page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/recent/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function actionContent(action, idContent) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/${idContent}/${action}`,
		requestOptions
	).then(handleResponse)
}

function getContentByUrl(url) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() }
	}

	return fetch(`${config.apiUrl}/content/${url}`, requestOptions).then(
		handleResponse
	)
}

function postComment(contentId, comment) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: JSON.stringify({
			comment: comment,
			content_indication_id: contentId
		})
	}

	return fetch(`${config.apiUrl}/comments/`, requestOptions).then(
		handleResponse
	)
}

function getUserFavorites(page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/users/content/my-favorites/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function putComment(contentId, comment) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: JSON.stringify({
			comment: comment
		})
	}

	return fetch(`${config.apiUrl}/comments/${contentId}`, requestOptions).then(
		handleResponse
	)
}

function deleteComment(contentId) {
	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		}
	}

	return fetch(`${config.apiUrl}/comments/${contentId}`, requestOptions).then(
		handleResponse
	)
}

function getAbout() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiUrl}/about/`, requestOptions).then(handleResponse)
}

function setAbout(body) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	}

	return fetch(`${config.apiUrl}/about/`, requestOptions).then(handleResponse)
}

function setAboutImage(file) {
	let formData = new FormData()
	formData.append('file', file)
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(`${config.apiUrl}/about/image`, requestOptions).then(
		handleResponse
	)
}

function getInfographics() {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(`${config.apiUrl}/infographics/`, requestOptions).then(
		handleResponse
	)
}

function setInfographic(body, id) {
	let method = id ? 'PUT' : 'POST'
	id = id ? id : ''
	const requestOptions = {
		method,
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	}

	return fetch(`${config.apiUrl}/infographics/${id}`, requestOptions).then(
		handleResponse
	)
}

function setInfographicFile(file, id, type) {
	let formData = new FormData()
	formData.append('file', file)
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(
		`${config.apiUrl}/infographics/${id}/${type}`,
		requestOptions
	).then(handleResponse)
}

function deleteInfographic(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/infographics/${id}`, requestOptions).then(
		handleResponse
	)
}
