import React, { forwardRef, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { searchActions } from '../../../app/_actions/search.actions'
import { useScrollDetails } from '../../../utils/hooks/useScrollDetails'
import { useInfiniteScroll } from '../../../utils/hooks/useInfiniteScroll'
import { useFilter } from '../../../context/advancedFilter'

import ContentBlock from '../ContentBlock'
import ContentDetail from '../ContentDetail'

import * as S from './styles'
import '../../../css/style.css'
import { useTranslation } from 'react-i18next'

const initialRow = {
	category: '',
	pos: { top: 0, bottom: 0 }
}

const FilteredContent = forwardRef(({ data }, ref) => {
	const dispatch = useDispatch()
	const loader = useRef(null)
	const [activeRow, setActiveRow] = useState(initialRow)
	const { filter, resetFilter } = useFilter()
	const { t } = useTranslation()
	const heightFixForWP = 110
	const modalHeightFixForWP = 1030

	const {
		category,
		pos: { top, bottom },
		item
	} = activeRow

	/* Ativa detalhes do conteúdo */
	const setActive = useCallback((row) => {
		row.category ? setActiveRow(row) : setActiveRow(initialRow)
	}, [])

	/* Scroll até os detalhes do conteúdo */
	useScrollDetails(category, top - heightFixForWP, ref)

	const getPos = useCallback(
		(e, item) => {
			const hasContent = e.target.classList.contains('contents')
			console.log(hasContent)
			let pos
			if (hasContent) {
				pos =
					e.target.parentElement.parentElement.getBoundingClientRect()
			} else {
				pos = e.target.parentElement.getBoundingClientRect()
			}
			setActive({ category: 'search', pos, item })
		},
		[setActive]
	)

	const { content, loading, has_next, page } = data
	const loadMore = useCallback(
		(entries) => {
			const target = entries[0]
			if (target.isIntersecting && has_next) {
				if (!loading) {
					dispatch(
						searchActions.loadMoreFilteredContent(
							filter,
							page + 1,
							10
						)
					)
				}
			}
		},
		[dispatch, filter, page, loading, has_next]
	)
	/* Listen to scroll event and load more */
	useInfiniteScroll(loader, loadMore)

	function handleClean() {
		dispatch(searchActions.resetFilteredContent())
		resetFilter()
	}

	return (
		<S.Container className="container">
			<S.Title>
				<h2>{t('search.filtering-by', 'Filtrando resultados por')}</h2>
				<ShowFilter filter={filter} />
				<button className="btn btn-warning" onClick={handleClean}>
					{t('search.clean-filter', 'Limpar filtro')}
				</button>
			</S.Title>
			<S.Wrap>
				{content.map((item) => {
					return (
						<ContentBlock
							key={`filtered_${item.id}`}
							text={item.title}
							img={item.cover_id + '.thumb'}
							onClick={(e) => getPos(e, item)}
							icon={item.content_type.icon_name}
						></ContentBlock>
					)
				})}
				<ContentDetail
					category={category}
					item={item}
					pos={bottom + window.scrollY - modalHeightFixForWP}
					setActive={setActive}
				/>
			</S.Wrap>
			<div ref={loader}>
				{content.length > 0 ? (
					<span>Carregando</span>
				) : (
					<span>
						Nenhum resultado encontrado com o filtro especificado.
					</span>
				)}
			</div>
		</S.Container>
	)
})

const ShowFilter = ({ filter }) => {
	return (
		<div>
			{filter.theme_id.length > 0 && (
				<strong>
					{filter.theme_id.map((item) => item.label).join(', ')}
				</strong>
			)}
			{filter.title && <strong>{filter.title}</strong>}
			{filter.content_type_id[0] && (
				<strong>{filter.content_type_id[0].name}</strong>
			)}
			{filter.regiao_id.length > 0 && (
				<ShowLabelLocation location={filter.regiao_id} />
			)}
			{filter.bioma_id.length > 0 && (
				<ShowLabelLocation location={filter.bioma_id} />
			)}
			{filter.estado_id.length > 0 && (
				<ShowLabelLocation location={filter.estado_id} />
			)}
			{filter.municipio_id.length > 0 && (
				<ShowLabelLocation location={filter.municipio_id} />
			)}
			{filter.uc_id && <ShowLabelLocation location={filter.uc_id} />}
			{filter.ti_id && <ShowLabelLocation location={filter.ti_id} />}
			{filter.keywords && <strong>{filter.keywords}</strong>}
		</div>
	)
}

const ShowLabelLocation = ({ location }) => {
	return location.map((item) => <strong key={item.id}>{item.label}</strong>)
}

export default FilteredContent
