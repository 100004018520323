import { locationConstants } from '../_constants'

export function biomes(state = { biomes: [] }, action) {
	switch (action.type) {
		case locationConstants.BIOME_REQUEST:
			return {
				fetchingBiome: true,
				...state
			}
		case locationConstants.BIOME_SUCCESS: {
			return {
				biomes: action.rows
			}
		}
		case locationConstants.BIOME_FAILURE:
			return {
				biomes: []
			}
		default:
			return state
	}
}
