import { keyframes } from 'styled-components'

export default {
	grid: {
		container: '130rem',
		gutter: '3.2rem'
	},
	border: {
		radius: '0.4rem'
	},
	font: {
		bold: 600,
		sizes: {
			xxsmall: '1.4rem',
			xsmall: '1.6rem',
			small: '1.8rem',
			medium: '2.2rem',
			large: '2.6rem',
			xlarge: '3.4rem',
			xxlarge: '5.2rem'
		}
	},
	colors: {
		primary: 'rgb(25, 76, 71)',
		primaryHover: 'rgba(25, 76, 71, .6)',
		primaryDark: 'rgb(5, 48, 44)',
		secondary: 'rgb(220, 94, 20)',
		secondaryHover: 'rgba(220, 94, 20, .6)',
		dark: 'rgba(51, 51, 51, 0.8)',
		darkLight: 'rgba(190, 190, 190, 1)',
		light: 'rgb(239 239 239)',
		darkHover: 'rgb(12, 12, 12)',
		danger: '#db2828'
	},
	lazy: keyframes`
        0% {
            background-color: #fff;
        }
        50% {
            background-color: rgb(239 239 239);
        }
        100% {
            background-color: #fff;
        }
    `
}
