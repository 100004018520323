export const isArrayEqual = (array1, array2) => {
	return array1.sort().toString() === array2.sort().toString()
}

export const truncate = (input, quantity) =>
	input?.length > quantity ? `${input.substring(0, quantity)}...` : input

export const hasRoleOrPermission = (
	requiredRoles,
	userRoles,
	requiredPermission,
	userPermissions
) => {
	let hasRole = false
	if (!requiredRoles && !requiredPermission) {
		return true
	}
	if (requiredRoles) {
		hasRole = userRoles.some((role) => requiredRoles.includes(role))
	}
	let hasPermission = false
	if (requiredPermission) {
		hasPermission = userPermissions.some((permission) =>
			requiredPermission.includes(permission)
		)
	}
	return hasRole || hasPermission
}

export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.addEventListener('load', () => resolve(reader.result), false)
		reader.readAsDataURL(file)
	})
}

export const getRoleLabel = (role) => {
	switch (role) {
		case 'ROLE_ADMIN':
			return 'Administrador'
		case 'ROLE_USER':
			return 'Usuário'
		case 'ROLE_ORGANIZATION':
			return 'Organização'
		case 'ROLE_CURADOR_CHEFE':
			return 'Curador-chefe'
		case 'ROLE_CURADOR':
			return 'Curador'
		default:
			return ''
	}
}

export const isSafari = () => {
	return (
		navigator.vendor &&
		navigator.vendor.indexOf('Apple') > -1 &&
		navigator.userAgent &&
		navigator.userAgent.indexOf('CriOS') == -1 &&
		navigator.userAgent.indexOf('FxiOS') == -1
	)
}
