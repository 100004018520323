import { contentConstants } from '../_constants'

export function content(
	state = {
		object: {}
	},
	action
) {
	switch (action.type) {
		case contentConstants.REQUEST:
		case contentConstants.USER_CONTENT_REQUEST:
		case contentConstants.UPDATE_REQUEST:
			return {
				...state,
				loading: true
			}
		case contentConstants.SUCCESS:
		case contentConstants.USER_CONTENT_SUCCESS:
			return {
				...state,
				data: action.data,
				loading: false
			}
		case contentConstants.ERROR:
		case contentConstants.USER_CONTENT_FAILURE:
			return {}
		case contentConstants.CREATE_REQUEST:
			return state
		case contentConstants.CREATE_SUCCESS:
			return {
				...state,
				object: action.data
			}
		case contentConstants.CLEAN:
			return {
				...state,
				object: {}
			}
		case contentConstants.CONTENT_TYPE_REQUEST:
			return {
				...state,
				[action.id]: { loading: true }
			}
		case contentConstants.CONTENT_TYPE_SUCCESS: {
			return {
				...state,
				[action.id]: { content: action.data.content }
			}
		}
		case contentConstants.DELETE_SUCCESS: {
			return {
				...state,
				loading: false
			}
		}
		case contentConstants.GET_FILTERED: {
			return {
				...state,
				filtered: {
					...action.data,
					loading: false,
					content: action.data.content,
					page: 1
				}
			}
		}
		case contentConstants.RESET_FILTERED: {
			return {
				...state,
				filtered: null
			}
		}
		case contentConstants.LOAD_MORE_FILTERED: {
			return {
				...state,
				filtered: {
					...state.filtered,
					content: [
						...state.filtered.content,
						...action.data.content
					],
					has_next: action.data.has_next,
					page: action.page
				}
			}
		}
		case contentConstants.SUCCESS_CONTENT_URL: {
			return {
				...state,
				urlContent: action.content
			}
		}
		case contentConstants.SUCCESS_ABOUT: {
			return {
				...state,
				loading: false,
				about: action.data
			}
		}
		case contentConstants.SUCCESS_FAVORITE: {
			return {
				...state,
				favorite: action.data
			}
		}
		case contentConstants.SUCCESS_INFOGRAPHIC: {
			return {
				...state,
				loading: false,
				infographics: action.data
			}
		}
		case contentConstants.UPDATE_INFOGRAPHIC: {
			return {
				...state,
				loading: false,
				selected: action.data
			}
		}
		case contentConstants.SET_COVER_REQUEST: {
			return {
				...state,
				loadingImage: true
			}
		}
		case contentConstants.SET_COVER_SUCCESS:
		case contentConstants.SET_COVER_FAILURE: {
			return {
				...state,
				loadingImage: false
			}
		}
		case contentConstants.UPDATE_FAILURE: {
			return {
				...state,
				loading: false
			}
		}
		case contentConstants.CLEAN_INFOGRAPHIC:
			return {
				...state,
				selected: {}
			}
		case contentConstants.REQUEST_CONTENT_URL:
		case contentConstants.FAILURE_CONTENT_URL:
		case contentConstants.CONTENT_ACTION_REQUEST:
		case contentConstants.CONTENT_ACTION_SUCCESS:
		case contentConstants.CONTENT_ACTION_FAILURE:
		case contentConstants.SET_REQUEST:
		case contentConstants.SET_SUCCESS:
		case contentConstants.SET_FAILURE:
		case contentConstants.DELETE_FAILURE:
		case contentConstants.PUBLISH_REQUEST:
		case contentConstants.PUBLISH_SUCCESS:
		case contentConstants.PUBLISH_FAILURE:
		case contentConstants.CONTENT_TYPE_FAILURE:
		case contentConstants.UPDATE_SUCCESS:
		case contentConstants.SET_FILE_REQUEST:
		case contentConstants.SET_FILE_SUCCESS:
		case contentConstants.SET_TYPE_THEMES_REQUEST:
		case contentConstants.SET_TYPE_THEMES_SUCCESS:
		case contentConstants.SET_FILE_FAILURE:
		case contentConstants.SET_TYPE_THEMES_FAILURE:
		default:
			return state
	}
}
