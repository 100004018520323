import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import {
	Comment,
	Header,
	Label,
	Form,
	Button,
	TransitionablePortal,
	Message,
	Modal
} from 'semantic-ui-react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'
import { truncate } from '../../../utils/functions'
import Icon from '../../Icon'
import ContentUserActions from '../ContentUserActions'
import ModalShare from '../ModalShare'
import { contentService } from '../../../app/_services'
import { alertActions } from '../../../app/_actions'
import history from '../../../app/history'

import * as S from './styles'

const ContentDetail = ({ category, pos, item, setActive, isPage }) => {
	const [selectedItem, setSelectedItem] = useState(null)
	const [openShare, setOpenShare] = useState(false)

	useEffect(() => {
		ReactTooltip.rebuild()
		setSelectedItem(null)
	}, [item])
	return (
		category && (
			<S.Detail pos={pos} isPage={isPage}>
				<Icon type="far fa-times" onClick={setActive} />
				<S.Wrapper className={category.id === 'series' && 'is-serie'}>
					{category.id !== 'series' && (
						<>
							<ContentInfo
								item={item}
								handleSelect={setSelectedItem}
								isPage={isPage}
							/>
							<S.Cover
								className="cover"
								bg={item.cover_id}
							></S.Cover>
						</>
					)}

					{category.id === 'series' && (
						<>
							{selectedItem && (
								<ContentInfo
									half
									item={selectedItem}
									handleSelect={setSelectedItem}
									isPage={isPage}
								/>
							)}
							{!selectedItem && (
								<S.InfoSerie>
									<h2>
										{item.name}
										<Icon
											onClick={() => setOpenShare(true)}
											style={{
												marginLeft: '10px',
												cursor: 'pointer'
											}}
											type="far fa-share-alt"
										/>
									</h2>
									<S.Cover
										className="cover"
										bg={item.cover_id}
									></S.Cover>
								</S.InfoSerie>
							)}
							<SeriesInfo
								handleSelect={setSelectedItem}
								selected={selectedItem}
								item={item}
							/>
						</>
					)}
				</S.Wrapper>
				<ModalShare
					open={openShare}
					handleOpen={setOpenShare}
					url={'serie/' + item.friendly_url}
				/>
			</S.Detail>
		)
	)
}

const ContentInfo = ({ item, half, handleSelect, isPage }) => {
	const isLogedIn = localStorage.getItem('token') ? true : false
	dayjs.extend(relativeTime)
	dayjs.locale('pt-br')
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [commentEditar, setCommentEditar] = useState({ id: '', comment: '' })
	const { type, message } = useSelector((state) => state.alert)
	const { user } = useSelector((state) => state.authentication)
	const isOpen = Boolean(type, message)
	if (item.comments) {
		item.comments.sort((a, b) => {
			if (a.created_at < b.created_at) return -1
			if (a.created_at > b.created_at) return 1
			return 0
		})
	}
	function handleClosePortal() {
		if (isOpen) dispatch(alertActions.clear())
	}

	const [itemState, setItemState] = useState([])
	const dispatch = useDispatch()
	useEffect(() => {
		ReactTooltip.rebuild()
		setItemState(item)
	}, [item, setItemState])

	function setNewComment(comment) {
		itemState.comments.push(comment)
		setItemState({ ...item, comments: itemState.comments })
		dispatch(alertActions.success('Comentário incluido com sucesso!'))
		document.getElementById('comment-form').value = ''
		ReactTooltip.rebuild()
	}

	function postComment() {
		if (!isLogedIn) {
			history.push('/login')
		}
		let comment = document.getElementById('comment-form').value
		if (comment.length == 0) {
			dispatch(alertActions.error('Comentários não podem ser vazios.'))
		} else {
			contentService.postComment(item.id, comment).then(
				(data) => setNewComment(data.comment),
				(error) => dispatch(alertActions.error(error))
			)
		}
	}

	function handleEditar(id, comment) {
		document.getElementById(id + 'form').hidden = false
		document.getElementById(id + 'span').hidden = true
		setCommentEditar({ id: id, comment: comment })
		ReactTooltip.rebuild()
	}

	function handleSubmit(event) {
		event.preventDefault()
		let newComment = document.getElementById(
			commentEditar.id + 'formText'
		).value
		contentService.putComment(commentEditar.id, newComment).then(
			(data) => {
				dispatch(alertActions.success(data.message))
				setIsOpenModal(false)
				document.getElementById(commentEditar.id + 'span').innerHTML =
					newComment
				document.getElementById(
					commentEditar.id + 'span'
				).hidden = false
				document.getElementById(commentEditar.id + 'form').hidden = true
			},
			(error) => dispatch(alertActions.error(error))
		)
		ReactTooltip.rebuild()
	}

	function handleCancelarSubmit(event) {
		event.preventDefault()
		document.getElementById(commentEditar.id + 'span').hidden = false
		document.getElementById(commentEditar.id + 'form').hidden = true
		ReactTooltip.rebuild()
	}

	function handleDeletar(id) {
		setCommentEditar({ id: id })
		setIsOpenModal(true)
	}

	function handleSubmitDeletar() {
		contentService.deleteComment(commentEditar.id).then(
			(data) => {
				dispatch(alertActions.success(data.message))
				setIsOpenModal(false)
				document
					.getElementById(commentEditar.id + 'commentComponent')
					.remove()
			},
			(error) => dispatch(alertActions.error(error))
		)
	}

	return (
		<S.WrapOverview className={half && 'half'}>
			<S.Overview isPage={isPage} className={half && 'half'}>
				<TransitionablePortal onClose={handleClosePortal} open={isOpen}>
					<Message
						style={{
							right: '30px',
							position: 'fixed',
							top: '80px',
							zIndex: 1000
						}}
						size="large"
						color={type}
					>
						<Message.Header>
							{type === 'green' && <>Sucesso!</>}
							{type === 'red' && <>Ocorreu um erro</>}
						</Message.Header>
						{message}
					</Message>
				</TransitionablePortal>
				<S.Header>
					<S.Info>
						<S.Title>{item.title}</S.Title>
						<S.FileLink>
							{item.file_id && (
								<a href={item.file_id} target="blank">
									<S.ContentFileLink
										data-for="content-detail"
										data-tip="Clique para baixar este arquivo"
									>
										<Icon type="far fa-download"></Icon>
										{item.file_filename}
									</S.ContentFileLink>
								</a>
							)}
							{item.content_link && (
								<a href={item.content_link} target="blank">
									<S.ContentFileLink
										isLink
										data-tip="Clique para acessar este conteúdo"
										data-for="content-detail"
									>
										<Icon type="external-link-alt"></Icon>
										Link externo
									</S.ContentFileLink>
								</a>
							)}
							<ContentUserActions
								id={item.id}
								url={item.friendly_url}
								handleSelect={handleSelect}
								item={item}
							/>
						</S.FileLink>
						<S.Date>Ano: {item.year}</S.Date>
						<S.Authors>
							Autores:{' '}
							{item.author
								? item.author.replace(/[,]/g, ', ')
								: ''}
						</S.Authors>
						{item.contributors && (
							<S.Colaborators>
								Colaboradores: {item.contributors}
							</S.Colaborators>
						)}
						{item.themes && (
							<S.Themes>
								<div>Temas:</div>
								{item.themes.map((t) => (
									<Label size="big" key={t.id}>
										{t.name}
									</Label>
								))}
							</S.Themes>
						)}
						{item.serie && item.serie.length > 0 && (
							<S.Themes>
								<div>Series:</div>
								{item.serie.map((t, key) => (
									<a
										style={{ margin: '0 .5em .5em 0' }}
										key={key}
										href={'/serie/' + t.friendly_url}
										target="blank"
									>
										<Label size="big" key={t.id}>
											{t.name}
										</Label>
									</a>
								))}
							</S.Themes>
						)}
					</S.Info>
				</S.Header>
				<S.Short>{item.short_description}</S.Short>
				{item.keywords && (
					<S.Keywords>
						<div>Palavras-chave: </div>
						{item.keywords.split(',').map((k, key) => (
							<Label
								color="black"
								size="big"
								key={`keywords_${key}`}
							>
								{k}
							</Label>
						))}
					</S.Keywords>
				)}
				<Modal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
					<Modal.Header icon="archive">
						Deletar comentário
					</Modal.Header>
					<Modal.Description>
						<p>
							Tem certeza que deseja deletar esse comentário ?
							Essa ação não poderá ser desfeita.
						</p>
					</Modal.Description>
					<Modal.Actions>
						<Button onClick={handleSubmitDeletar} color="blue">
							Deletar
						</Button>
						<Button
							onClick={() => {
								setIsOpenModal(false)
							}}
							color="red"
						>
							Cancelar
						</Button>
					</Modal.Actions>
				</Modal>
				{isPage && itemState.comments && (
					<Comment.Group size="large">
						<Header as="h3" dividing>
							Comentários
						</Header>
						{itemState.comments.map((t) => (
							<Comment key={t.id} id={t.id + 'commentComponent'}>
								<Comment.Avatar
									src={
										t.user.photo_id ? (
											t.user.photo_id
										) : (
											<Icon
												type="fa fa-user"
												style={{
													fontSize: '25px',
													margin: '0'
												}}
											/>
										)
									}
								/>
								<Comment.Content>
									<Comment.Author as="a">
										{t.user.name}
									</Comment.Author>
									<Comment.Metadata
										data-tip={dayjs(t.created_at).format(
											'DD/MM/YYYY - HH:mm:ss'
										)}
										data-for="content-detail"
									>
										- {dayjs(t.created_at).fromNow()}
									</Comment.Metadata>
									<Comment.Text id={t.id + 'text'}>
										<Form hidden id={t.id + 'form'}>
											<Form.TextArea
												id={t.id + 'formText'}
												defaultValue={t.comment}
											/>
											<Button
												color="blue"
												onClick={(event) => {
													handleSubmit(event)
												}}
											>
												Salvar
											</Button>
											<Button
												color="red"
												onClick={(event) => {
													handleCancelarSubmit(event)
												}}
											>
												Cancelar
											</Button>
										</Form>
										<pre id={t.id + 'span'}>
											{t.comment}
										</pre>
									</Comment.Text>
									{isLogedIn && user.id == t.user.id && (
										<Comment.Actions>
											<Comment.Action
												onClick={() =>
													handleEditar(
														t.id,
														t.comment
													)
												}
											>
												Editar
											</Comment.Action>
											<Comment.Action
												onClick={() =>
													handleDeletar(t.id)
												}
											>
												Deletar
											</Comment.Action>
										</Comment.Actions>
									)}
								</Comment.Content>
							</Comment>
						))}
						<Form reply>
							{!isLogedIn && (
								<Form.TextArea
									id="comment-form"
									value="É necessário realizar o login para enviar um comentário"
									disabled
								/>
							)}
							{isLogedIn && <Form.TextArea id="comment-form" />}
							<Button
								disabled={!isLogedIn}
								data-tip={
									isLogedIn
										? 'Comentar'
										: 'É necessário realizar o login para enviar um comentário'
								}
								data-for="content-detail"
								content="Comentar"
								labelPosition="left"
								icon="edit"
								primary
								onClick={postComment}
							/>
						</Form>
					</Comment.Group>
				)}
			</S.Overview>
			<ReactTooltip id="content-detail" />
		</S.WrapOverview>
	)
}

const SeriesInfo = ({ item, selected, handleSelect }) => {
	return (
		<S.Episodes>
			<h2>Conteúdos da série</h2>
			<S.List>
				{item.content_indications.map((episode, i) => (
					<S.Item
						className={
							selected && selected.id === episode.id && 'selected'
						}
						key={i}
						onClick={() => handleSelect(episode)}
					>
						<S.Image img={episode.cover_id}></S.Image>
						<S.Description>
							<p className="title">{episode.title}</p>
							<br />
							<p>{truncate(episode.short_description, 200)}</p>
						</S.Description>
					</S.Item>
				))}
			</S.List>
		</S.Episodes>
	)
}

export default ContentDetail
