import { userConstants } from '../_constants'

export function users(state = { users: [] }, action) {
	switch (action.type) {
		case userConstants.GETALL_REQUEST:
			return {
				loading: true,
				...state
			}
		case userConstants.GETALL_SUCCESS:
			return {
				rows: action.users
			}
		case userConstants.GETALL_FAILURE:
			return {
				error: action.error
			}
		case userConstants.CHANGEPHOTO_REQUEST:
			return {
				loading: true
			}
		case userConstants.CHANGEPHOTO_SUCCESS:
			return {}
		case userConstants.CHANGEPASSWORD_REQUEST:
			return {
				loading: true
			}
		case userConstants.DELETE_SUCCESS: {
			return {
				...state,
				loading: false
			}
		}
		case userConstants.REGISTER_REQUEST:
		case userConstants.REGISTER_SUCCESS:
		case userConstants.REGISTER_FAILURE:
		case userConstants.CHANGEPHOTO_FAILURE:
		case userConstants.CHANGEPASSWORD_FAILURE:
		case userConstants.CHANGEPASSWORD_SUCCESS:
			return {}
		default:
			return state
	}
}
