import { orgsConstants } from '../_constants'

export function orgs(state = { orgs: [] }, action) {
	switch (action.type) {
		case orgsConstants.GETALL_REQUEST:
			return {
				loadingOrgs: true
			}
		case orgsConstants.GETALL_SUCCESS:
			return {
				orgs: action.rows
			}
		case orgsConstants.GETALL_FAILURE:
			return {
				error: action.error
			}
		case orgsConstants.DELETE_REQUEST:
		case orgsConstants.DELETE_SUCCESS:
		case orgsConstants.DELETE_FAILURE:
		default:
			return state
	}
}
