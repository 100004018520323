import { locationConstants } from '../_constants'
import { locationService } from '../_services'

export const locationActions = {
	getRegions,
	getStates,
	getCities,
	getBiomes,
	getConservations,
	getIndigenous
}

function getRegions() {
	return (dispatch) => {
		dispatch(request())
		locationService.getRegions().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.REGION_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.REGION_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.REGION_FAILURE, error }
	}
}
function getStates() {
	return (dispatch) => {
		dispatch(request())
		locationService.getStates().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.STATE_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.STATE_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.STATE_FAILURE, error }
	}
}

function getCities(state) {
	return (dispatch) => {
		dispatch(request())
		locationService.getCities(state).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.CITY_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.CITY_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.CITY_FAILURE, error }
	}
}

function getBiomes(state) {
	return (dispatch) => {
		dispatch(request())
		locationService.getBiomes(state).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.BIOME_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.BIOME_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.BIOME_FAILURE, error }
	}
}

function getConservations(state) {
	return (dispatch) => {
		dispatch(request())
		locationService.getConservations(state).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.CONSERVATION_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.CONSERVATION_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.CONSERVATION_FAILURE, error }
	}
}

function getIndigenous(state) {
	return (dispatch) => {
		dispatch(request())
		locationService.getIndigenous(state).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: locationConstants.INDIGENOUS_REQUEST }
	}
	function success(rows) {
		return { type: locationConstants.INDIGENOUS_SUCCESS, rows }
	}
	function failure(error) {
		return { type: locationConstants.INDIGENOUS_FAILURE, error }
	}
}
