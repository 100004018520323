import React from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'

const Placeholder = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	animation: ${({ theme }) => theme.lazy} 1s infinite;
`

const LazyImage = ({ src, alt }) => {
	const refPlaceholder = React.useRef()

	const removePlaceholder = () => {
		refPlaceholder.current.remove()
	}

	return (
		<div>
			<Placeholder ref={refPlaceholder} />
			<LazyLoad>
				<img
					onLoad={removePlaceholder}
					onError={removePlaceholder}
					src={src}
					alt={alt}
				/>
			</LazyLoad>
		</div>
	)
}

export default LazyImage
