import { seriesConstants } from '../_constants'

export function series(state = {}, action) {
	switch (action.type) {
		case seriesConstants.USER_SERIE_REQUEST:
			return {
				loading: true
			}
		case seriesConstants.SUCCESS:
		case seriesConstants.USER_SERIE_SUCCESS:
			return {
				data: action.data,
				loading: false
			}
		case seriesConstants.USER_SERIE_FAILURE:
			return {}
		case seriesConstants.CREATE_REQUEST:
			return state
		case seriesConstants.CREATE_SUCCESS:
			return {
				object: action.data
			}
		case seriesConstants.DELETE_SUCCESS: {
			const series = state.data.series.filter((s) => s.id !== action.id)
			return {
				data: { ...state.data, series }
			}
		}
		case seriesConstants.SUCCESS_URL: {
			return {
				...state,
				urlSerie: action.serie
			}
		}
		case seriesConstants.REQUEST: {
			return {
				...state,
				loading: true
			}
		}
		case seriesConstants.REQUEST_URL:
		case seriesConstants.FAILURE_URL:
		case seriesConstants.ERROR:
		case seriesConstants.DELETE_REQUEST:
		case seriesConstants.DELETE_FAILURE:
		case seriesConstants.CREATE_ERROR:
		case seriesConstants.UPDATE_SUCCESS:
		case seriesConstants.UPDATE_REQUEST:
		case seriesConstants.UPDATE_ERROR:
			return state
		default:
			return state
	}
}
