export const locationConstants = {
	REGION_REQUEST: 'LOCATION_REGION_REQUEST',
	REGION_SUCCESS: 'LOCATION_REGION_SUCCESS',
	REGION_FAILURE: 'LOCATION_REGION_FAILURE',

	STATE_REQUEST: 'LOCATION_STATE_REQUEST',
	STATE_SUCCESS: 'LOCATION_STATE_SUCCESS',
	STATE_FAILURE: 'LOCATION_STATE_FAILURE',

	CITY_REQUEST: 'LOCATION_CITY_REQUEST',
	CITY_SUCCESS: 'LOCATION_CITY_SUCCESS',
	CITY_FAILURE: 'LOCATION_CITY_FAILURE',

	BIOME_REQUEST: 'LOCATION_BIOME_REQUEST',
	BIOME_SUCCESS: 'LOCATION_BIOME_SUCCESS',
	BIOME_FAILURE: 'LOCATION_BIOME_FAILURE',

	CONSERVATION_REQUEST: 'LOCATION_CONSERVATION_REQUEST',
	CONSERVATION_SUCCESS: 'LOCATION_CONSERVATION_SUCCESS',
	CONSERVATION_FAILURE: 'LOCATION_CONSERVATION_FAILURE',

	INDIGENOUS_REQUEST: 'LOCATION_INDIGENOUS_REQUEST',
	INDIGENOUS_SUCCESS: 'LOCATION_INDIGENOUS_SUCCESS',
	INDIGENOUS_FAILURE: 'LOCATION_INDIGENOUS_FAILURE'
}
