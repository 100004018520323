import styled from 'styled-components'

export const Container = styled.div`
	position: relative;
	text-align: center;
	color: ${({ theme }) => theme.colors.secondary};
	top: -30px;
	font-size: 2.5rem;
	padding: 6px 0;
	text-transform: uppercase;
	animation: bounce 0.82s infinite alternate;
	z-index: 10;
	width: 50px;
	display: flex;
	justify-content: center;
	width: 100%;
	i {
		cursor: pointer;
	}
`
