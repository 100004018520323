import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const searchService = {
	getFilteredContent,
	loadMoreFilteredContent
}

const sanitizeFilter = (filter) => {
	return {
		...filter,
		content_type_id: filter.content_type_id.map((item) => item.id),
		theme_id: filter.theme_id.map((item) => item.id),
		regiao_id: filter.regiao_id ? filter.regiao_id.id : null,
		bioma_id: filter.bioma_id ? filter.bioma_id.id : null,
		estado_id: filter.estado_id ? filter.estado_id.id : null,
		municipio_id: filter.municipio_id ? filter.municipio_id.id : null,
		uc_id: filter.uc_id ? filter.uc_id.id : null,
		ti_id: filter.ti_id ? filter.ti_id.id : null
	}
}

function getFilteredContent(params, page, perPage) {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(sanitizeFilter(params)),
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/advanced-search/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function loadMoreFilteredContent(params, page, perPage) {
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(sanitizeFilter(params)),
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/advanced-search/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}
