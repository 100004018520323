import { contentConstants } from '../_constants'
import { contentService } from '../_services'
import { alertActions } from './alert.actions'

export const contentActions = {
	getAllContent,
	getAllContentToApproval,
	getAllContentByUser,
	getAllContentByType,
	createContent,
	updateContent,
	deleteContent,
	publishContent,
	setTypeAndThemes,
	setFile,
	cleanContent,
	setCover,
	defineContent,
	setCurator,
	getContentById,
	actionContent,
	getContentByUrl,
	getUserFavorites,
	getAbout,
	setAbout,
	setAboutImage,
	getInfographics,
	setInfographic,
	setInfographicFile,
	cleanInfographic,
	deleteInfographic
}

function getAllContent(page, perPage, search = null) {
	return (dispatch) => {
		dispatch(request())
		contentService.getAllContent(page, perPage, search).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: contentConstants.REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.FAILURE, error }
	}
}

function getAllContentToApproval(page, perPage, curators) {
	return (dispatch) => {
		dispatch(request())
		contentService.getAllContentToApproval(page, perPage, curators).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: contentConstants.REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.FAILURE, error }
	}
}

function createContent(content) {
	return (dispatch) => {
		dispatch(request())
		contentService.createContent(content).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: contentConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.CREATE_FAILURE, error }
	}
}

function updateContent(idContent, content) {
	return (dispatch) => {
		dispatch(request())
		contentService.updateContent(idContent, content).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: contentConstants.UPDATE_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.UPDATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.UPDATE_FAILURE, error }
	}
}

function deleteContent(idContent) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.deleteContent(idContent).then(
				(data) => {
					dispatch(success(idContent))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.DELETE_REQUEST }
	}
	function success(id) {
		return { type: contentConstants.DELETE_SUCCESS, id }
	}
	function failure(error) {
		return { type: contentConstants.DELETE_FAILURE, error }
	}
}

function publishContent(idContent) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.publishContent(idContent).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.PUBLISH_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.PUBLISH_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.PUBLISH_FAILURE, error }
	}
}

function setTypeAndThemes(idContent, content_type_id, themes) {
	return (dispatch) => {
		dispatch(request())
		contentService
			.setTypeAndThemes(idContent, content_type_id, themes)
			.then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
				}
			)
	}

	function request() {
		return { type: contentConstants.SET_TYPE_THEMES_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SET_TYPE_THEMES_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_TYPE_THEMES_FAILURE, error }
	}
}

function setFile(idContent, file) {
	return (dispatch) => {
		dispatch(request())
		contentService.setFile(idContent, file).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: contentConstants.SET_FILE_FAILURE }
	}
	function success(data) {
		return { type: contentConstants.SET_FILE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_FILE_FAILURE, error }
	}
}

function cleanContent() {
	return (dispatch) => {
		dispatch({ type: contentConstants.CLEAN })
	}
}

function setCover(idContent, file) {
	return (dispatch) => {
		dispatch(request())
		contentService.setCover(idContent, file).then(
			(data) => {
				dispatch(success(data))
				dispatch(alertActions.success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return { type: contentConstants.SET_COVER_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SET_COVER_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_COVER_FAILURE, error }
	}
}

function defineContent(idContent, type, message) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.defineContent(idContent, type, message).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.SET_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_FAILURE, error }
	}
}

function setCurator(idContent, curator_id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.setCurator(idContent, curator_id).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.SET_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_FAILURE, error }
	}
}

function getContentById(idContent) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.getContentById(idContent).then(
				(data) => {
					dispatch(success(data))
					resolve(data)
				},
				(error) => {
					dispatch(failure(error.toString()))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.CREATE_FAILURE, error }
	}
}

function getAllContentByUser(page, perPage, idUser) {
	return (dispatch) => {
		dispatch(request())

		contentService.getAllContentByUser(page, perPage, idUser).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: contentConstants.USER_CONTENT_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.USER_CONTENT_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.USER_CONTENT_FAILURE, error }
	}
}

function getAllContentByType(page, perPage, idType) {
	return (dispatch) => {
		dispatch(request())

		if (idType === 'recent') {
			contentService.getAllRecentContent(page, perPage, idType).then(
				(data) => dispatch(success(data)),
				(error) => dispatch(failure(error.toString()))
			)
		} else {
			contentService.getAllContentByType(page, perPage, idType).then(
				(data) => dispatch(success(data)),
				(error) => dispatch(failure(error.toString()))
			)
		}
	}

	function request() {
		return { type: contentConstants.CONTENT_TYPE_REQUEST, id: idType }
	}
	function success(data) {
		return { type: contentConstants.CONTENT_TYPE_SUCCESS, id: idType, data }
	}
	function failure(error) {
		return { type: contentConstants.CONTENT_TYPE_FAILURE, error }
	}
}

function actionContent(action, idContent) {
	return (dispatch) => {
		dispatch(request())

		contentService.actionContent(action, idContent).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return {
			type: contentConstants.CONTENT_ACTION_REQUEST,
			action,
			id: idContent
		}
	}
	function success() {
		return {
			type: contentConstants.CONTENT_ACTION_SUCCESS,
			action,
			id: idContent
		}
	}
	function failure() {
		return {
			type: contentConstants.CONTENT_ACTION_FAILURE,
			action,
			id: idContent
		}
	}
}

function getContentByUrl(url) {
	return (dispatch) => {
		dispatch(request(url))

		contentService.getContentByUrl(url).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request(url) {
		return {
			type: contentConstants.REQUEST_CONTENT_URL,
			url
		}
	}
	function success(content) {
		return {
			type: contentConstants.SUCCESS_CONTENT_URL,
			content
		}
	}
	function failure() {
		return {
			type: contentConstants.FAILURE_CONTENT_URL
		}
	}
}

function getUserFavorites(page, perPage) {
	return (dispatch) => {
		dispatch(request())

		contentService.getUserFavorites(page, perPage).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return {
			type: contentConstants.REQUEST
		}
	}
	function success(data) {
		return {
			type: contentConstants.SUCCESS_FAVORITE,
			data
		}
	}
	function failure() {
		return {
			type: contentConstants.FAILURE
		}
	}
}

function getAbout() {
	return (dispatch) => {
		dispatch(request())
		contentService.getAbout().then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: contentConstants.REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SUCCESS_ABOUT, data }
	}
	function failure(error) {
		return { type: contentConstants.FAILURE, error }
	}
}

function setAbout(body) {
	return (dispatch) => {
		dispatch(request())

		contentService.setAbout(body).then(
			(data) => {
				dispatch(alertActions.success(data.message))
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return {
			type: contentConstants.UPDATE_REQUEST
		}
	}
	function success(data) {
		return {
			type: contentConstants.UPDATE_SUCCESS,
			data
		}
	}
	function failure() {
		return {
			type: contentConstants.UPDATE_FAILURE
		}
	}
}

function setAboutImage(file) {
	return (dispatch) => {
		dispatch(request())

		contentService.setAboutImage(file).then(
			(data) => {
				dispatch(alertActions.success(data.message))
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.message))
			}
		)
	}

	function request() {
		return {
			type: contentConstants.UPDATE_REQUEST
		}
	}
	function success() {
		return {
			type: contentConstants.SUCCESS
		}
	}
	function failure() {
		return {
			type: contentConstants.UPDATE_FAILURE
		}
	}
}

function getInfographics() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.getInfographics().then(
				(data) => {
					dispatch(success(data))
					resolve(data)
				},
				(error) => {
					dispatch(failure(error.toString()))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SUCCESS_INFOGRAPHIC, data }
	}
	function failure(error) {
		return { type: contentConstants.FAILURE, error }
	}
}

function setInfographic(body, id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.setInfographic(body, id).then(
				(data) => {
					dispatch(alertActions.success(data.message))
					dispatch(success(data))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return {
			type: contentConstants.UPDATE_REQUEST
		}
	}
	function success(data) {
		return {
			type: contentConstants.UPDATE_INFOGRAPHIC,
			data
		}
	}
	function failure() {
		return {
			type: contentConstants.UPDATE_FAILURE
		}
	}
}

function setInfographicFile(file, id, type) {
	return (dispatch) => {
		dispatch(request())
		contentService.setInfographicFile(file, id, type).then(
			(data) => {
				dispatch(alertActions.success(data.message))
				dispatch(success(data.message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error))
			}
		)
	}

	function request() {
		return {
			type: contentConstants.UPDATE_REQUEST
		}
	}
	function success() {
		return {
			type: contentConstants.SUCCESS
		}
	}
	function failure() {
		return {
			type: contentConstants.UPDATE_FAILURE
		}
	}
}

function deleteInfographic(id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.deleteInfographic(id).then(
				(data) => {
					dispatch(success(id))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.DELETE_REQUEST }
	}
	function success(id) {
		return { type: contentConstants.DELETE_INFOGRAPHIC_SUCCESS, id }
	}
	function failure(error) {
		return { type: contentConstants.DELETE_FAILURE, error }
	}
}

function cleanInfographic() {
	return (dispatch) => {
		dispatch({ type: contentConstants.CLEAN_INFOGRAPHIC })
	}
}
