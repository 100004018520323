import styled from 'styled-components'

export const Container = styled.div`
	overflow-x: hidden;
	margin: 20px 0;
	h2 {
		margin: 20px 0 10px;
		color: ${({ theme }) => theme.colors.primary};
		@media screen and (max-width: 1024px) {
			font-size: 18px;
		}
	}
`

export const Title = styled.div`
	display: flex;
	align-items: baseline;
	flex-direction: column;
	margin-bottom: 32px;
	h2 {
		padding-right: 20px;
	}
	strong {
		text-transform: uppercase;
		margin-right: 10px;
	}
`

export const Wrap = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(357px, 1fr));
	grid-gap: 10px;
	@media screen and (max-width: 800px) {
		grid-template-columns: unset;
	}
`

export const Buttons = styled.div`
	position: absolute;
	width: unset !important;
	display: flex;
	> button.default {
		font-size: 1.5em;
		height: 35px;
		padding: 0 1em;
		border-radius: 4px;
		:last-child {
			background-color: ${({ theme }) => theme.colors.primary};
			:hover {
				background-color: ${({ theme }) => theme.colors.primaryDark};
			}
		}
	}
`
