import { orgsConstants } from '../_constants'
import { orgsService } from '../_services'
import { alertActions } from './alert.actions'

export const orgsActions = {
	getAll,
	delete: _delete
}

function _delete(id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request(id))
			orgsService.delete(id).then(
				(data) => {
					dispatch(success(id))
					dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(alertActions.error('Erro'))
					dispatch(failure(id, error.toString()))
					reject()
				}
			)
		})
	}

	function request(id) {
		return { type: orgsConstants.DELETE_REQUEST, id }
	}
	function success(id) {
		return { type: orgsConstants.DELETE_SUCCESS, id }
	}
	function failure(id, error) {
		return { type: orgsConstants.DELETE_FAILURE, id, error }
	}
}

function getAll(page, perPage, q) {
	return (dispatch) => {
		dispatch(request())

		orgsService.getAll(page, perPage, q).then(
			(rows) => dispatch(success(rows)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: orgsConstants.GETALL_REQUEST }
	}
	function success(rows) {
		return { type: orgsConstants.GETALL_SUCCESS, rows }
	}
	function failure(error) {
		return { type: orgsConstants.GETALL_FAILURE, error }
	}
}
