import styled from 'styled-components'

export const Actions = styled.div`
	display: flex;
	font-size: 20px;
	color: ${({ theme }) => theme.colors.primary};
	margin: 0 10px;
	@media screen and (max-width: 1024px) {
		justify-content: center;
		margin-top: 20px;
	}
	> div {
		margin: 0 5px;
		transition: color 0.25s linear;
		cursor: pointer;
		:hover {
			color: ${({ theme }) => theme.colors.primaryHover};
		}
	}
`
