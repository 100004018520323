import { userConstants } from '../_constants'

export function authentication(state = {}, action) {
	switch (action.type) {
		case userConstants.LOGIN_REQUEST:
			return {
				loggingIn: true
			}
		case userConstants.LOGIN_SUCCESS: {
			return {
				loggedIn: true,
				user: action.user
			}
		}
		case userConstants.LOGIN_FAILURE:
			return {
				loggedIn: false
			}
		case userConstants.FORGOT_REQUEST:
			return {
				fetchingForgot: true
			}
		case userConstants.FORGOT_SUCCESS: {
			return {
				emailSent: true
			}
		}
		case userConstants.FORGOT_FAILURE:
			return {}
		case userConstants.NEWPASSWORD_REQUEST:
			return {
				fetchingNewPassword: true
			}
		case userConstants.NEWPASSWORD_SUCCESS: {
			return {
				changedNewPassword: true
			}
		}
		case userConstants.NEWPASSWORD_FAILURE:
			return {}
		case userConstants.LOGOUT:
			return {}
		default:
			return state
	}
}
